@tailwind base;
@tailwind components;
@tailwind utilities;

.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Bebas Neue',
    sans-serif;
    font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 0.7rem;
}

body::-webkit-scrollbar-track {
  background-color: #FFEDD3;
}

body::-webkit-scrollbar-thumb {
  background-color: #022b3a;
  border-radius: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

